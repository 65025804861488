#contactPage {
  background-color: #121212;
  color: white;
  overflow: hidden;
  width: 100vw;
  max-width: 75rem;
  text-align: center;
  margin: 8rem auto;
}

.contactPageTitle {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  margin: 3rem;
}

.leftright {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  text-align: center;
  margin: 4rem auto;
}

.leftHalf {
  /* padding-top: 8rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 30rem;
}
.contactLinkText {
  text-align: center;
}
.contactLinks {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.rightHalf {
  margin-top: 3rem;
  margin: auto;
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2rem;
}

.contactMeDesc {
  font-weight: 200;
  letter-spacing: 0px;
}

.name,
.email,
.msg,
.subject {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  color: white;
  border-radius: 0.5rem;
  background: rgb(40, 40, 40);
  border: none;
  padding: 0.5rem 1rem;
}

.linkImg {
  height: 3rem;
  width: 3rem;
  margin: 1rem;
}

.submitBtn {
  background: white;
  color: black;
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 464px) {
  .leftright {
    flex-direction: column;
    gap: 40px;
  }
  .leftHalf {
    width: 350px;
  }

  .contactLinks {
    justify-content: space-between;
  }

  .rightHalf {
    width: 350px;
  }
}
