#intro {
  height: calc(100vh - 5rem);
  /* height: 100vh; */
  width: 100vw;
  max-width: 95rem;
  margin: 0rem auto;
  position: relative;
}
.introPage {
  padding-top: 0rem;
  display: flex;
  flex-direction: row;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  margin: 0rem;
}
.typewriter {
  font-weight: 600;
}
.introLeft {
  width: 25rem;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* padding: auto; */
}
.introImage {
  /* position: absolute; */
  z-index: -1;
  margin-bottom: 6rem;
  /* top: 5rem; */
  right: 0;
  object-fit: cover;
  height: 17rem;
  width: 17rem;
}
.introContent {
  height: 100vh;
  width: 100vw;
  padding: 1rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.hello {
  font-size: 1.5rem;
  font-weight: 250;
}

.introName {
  color: #ff3131;
}

.introPara {
  color: #a7a7a7;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 2rem;
  letter-spacing: 1px;
}
.bg {
  position: absolute;
  top: -4rem;
  z-index: -1;
  right: 0;
  object-fit: cover;
  height: 100vh;
}
.btn {
  background: white;
  color: black;
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  margin: 2rem 0rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  font-size: 1rem;
  transition: all 0.3s;
}

.location {
  margin: 3rem 0rem;
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
}
.location > p {
  font-size: 1.5rem;
  font-weight: 250;
}
.locationImg {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
}

.btn:hover {
  background: #ff3131;
  color: black;
  cursor: pointer;
}

.btnImg {
  cursor: pointer;
  object-fit: cover;
  height: 2rem;
  width: 2rem;
  margin: 0rem 0.5rem;
}

@media screen and (max-width: 464px) {
  .introPage {
    flex-direction: column;
    gap: 1rem;
  }
  .introContent {
    height: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
  .introImage {
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    margin-bottom: 0;
  }

  .introText {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .location {
    margin-bottom: 5rem;
  }
}
