#experience {
  background-color: #121212;
  color: white;
  overflow: hidden;
  /* min-height: calc(100vh - 4rem); */
  width: 100vw;
  max-width: 95rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 4rem auto;
}

.experienceCompany {
  font-weight: 600;
  /* color: #357960; */
}

.experienceRole {
  font-weight: 400;
  color: #cecece;
}
.experienceTitle {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.experienceLocation {
  font-weight: 200;
}

.list {
  display: flex;
  gap: 10rem;
  /* justify-content: space-between; */
}

.header {
  display: flex;

  /* justify-content: space-between; */
  gap: 1rem;
}

.experienceYear {
  color: #808080;
}

.individualExperience {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  /* align-items: right; */
  justify-content: center;
  text-align: left;
  border-left: 2px solid #ff3131;

  border-radius: 0px;
  padding-left: 1rem;
}

@media screen and (max-width: 1024px) {
  .list {
    gap: 2rem;
  }
}

@media screen and (max-width: 464px) {
  .list {
    flex-direction: column;
    gap: 2rem;
  }
}
