.techstack {
  background-color: #121212;
  color: white;
  overflow: hidden;
  /* min-height: calc(100vh - 4rem); */
  width: 100vw;
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 8rem auto;
}

.techStackTitle {
  color: white;
  font-size: 3rem;
  font-weight: 600;
}

.techStackDesc {
  font-size: 1.25rem;
  max-width: 65rem;
  letter-spacing: 1px;
  font-weight: 200;
  color: #a7a7a7;
  margin: 1rem 1rem 3rem 1rem;
}

.skillImg {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
}

.row1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 10rem;
}

.row3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.individualSkill {
  margin: 1rem 5.5rem;
}

.individualSkill:hover {
  color: red;
}
