.navbar {
  background-color: #121212;
  height: 5rem;
  width: 100vw;
  /* max-width: 75rem; */
  margin: 0rem auto;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
  opacity: 0.95;
}

.websiteLogo {
  object-fit: cover;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
}

.desktopMenuListItem {
  margin: 1rem;
  font-size: large;
  cursor: pointer;
}

.desktopMenuListItem:hover {
  color: #ff3131;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #ff3131;
}

.desktopMenuIcon {
  cursor: pointer;
  object-fit: cover;
  height: 2rem;
  width: 2rem;
  margin: 1rem 0.75rem 1rem 0.5rem;
}

.desktopMenuBtn {
  cursor: pointer;
  background: #ff3131;
  color: black;
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0 1.5rem 0 1.5rem;
  border-radius: 2rem;
}

.active {
  color: #ff3131;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #ff3131;
}

.mobMenu {
  position: absolute;
  top: 3rem;
  right: 2rem;
  object-fit: cover;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  display: none;
}

.mobileMenu {
  position: absolute;
  top: 5rem;
  right: 4rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  z-index: 3;
  height: fit-content;
  cursor: pointer;
  min-width: 15rem;
  border-radius: 1rem;
  background-color: rgb(40, 40, 40);
}

.listItem {
  color: white;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  background: rgb(30, 30, 30);
  border-radius: 0.2rem;
}

/* @media screen and (max-width: 1200px) {
  .navbar {
    max-width: 1080px;
  }
} */

/* @media screen and (max-width: 1080px) {
  .navbar {
    max-width: 720px;
  }
} */

@media screen and (max-width: 464px) {
  .mobMenu {
    display: flex;
  }
  .desktopMenu {
    display: none;
  }
  .desktopMenuBtn {
    display: none;
  }
  .websiteLogo {
    height: 4rem;
    width: 4rem;
  }
}
