#skills {
  background-color: #121212;
  color: white;
  overflow: hidden;
  width: 100vw;
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.skillBars {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.5rem;
  width: 100vw;
  max-width: 50rem;
  margin: auto;
  /* overflow-y: auto; */
}
.skillBar {
  transition: all 0.3s;
  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  border-radius: 2rem;
  background: rgb(30, 30, 30);
}

.skillBar:hover {
  box-shadow: 0 0 11px rgba(236, 233, 233, 0.8);
  scale: 1.01;
}

.skillBarText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0rem;
  font-size: 1rem;
  color: #4032a8;
}

.skillBarText > h2 {
  color: #92a4e2bd;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}

.skillBarText > p {
  font-size: 1rem;
  font-weight: 200;
  font-family: "Outfit", sans-serif;
  letter-spacing: 1px;
  color: white;
}

.skillBarImg {
  height: 8rem;
  width: 8rem;
  object-fit: cover;
  margin: 0rem 5rem;
}

.skillTitle {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  /* border-bottom: 1px; */
  /* padding-top: 3rem; */
}

.skillDesc {
  margin: 1rem 1rem 5rem 1rem;
  font-size: 1.25rem;
  max-width: 75rem;
  letter-spacing: 1px;
  font-weight: 200;
  color: #a7a7a7;
}

.horizontalLine {
  width: 20%;
  margin: 1rem 0rem;
  height: 1px;
}

@media screen and (max-width: 1024px) {
  .skillBarText > h2 {
    font-size: 1rem;
    font-weight: 600;
  }

  .skillBarText > p {
    font-size: 0.8rem;
    font-weight: 200;
  }
  .skillBar {
    padding: 0.5rem;
    margin: 0.5rem;
  }
  .skillBarImg {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
    margin: 0rem auto;
    text-align: center;
  }
}

@media screen and (max-width: 464px) {
  /* .skillBarText > h2 {
    font-size: 2rem;
  }
  .skillBarText > p {
    font-size: 1rem;
  } */
  .skillBars {
    flex-direction: column;
  }
}
